var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    _vm._b(
      {
        attrs: {
          "form-action-method": _vm.formActionWindow,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          "page-path": _vm.pagePath,
          title: _vm.title,
          "title-icon": "mdi-file-table",
          "initial-width": "4"
        }
      },
      "asyent-window",
      _vm.$attrs,
      false
    ),
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "General", expandable: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3"
                                            }
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value: _vm.formDataF["id"],
                                                label: "Consignment ID"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3"
                                            }
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value: [
                                                  _vm.formDataF["originatorId"],
                                                  _vm.formDataF[
                                                    "originatorName"
                                                  ]
                                                ],
                                                label: "Originator"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3"
                                            }
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value:
                                                  _vm.formDataF["providerName"],
                                                label: "Provider"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3"
                                            }
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value:
                                                  _vm.formDataF[
                                                    "providerObjectId"
                                                  ],
                                                label: "Provider Consignment ID"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3"
                                            }
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value:
                                                  _vm.formDataF[
                                                    "carrierBookingNumber"
                                                  ],
                                                label: "Carrier Booking Number"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3"
                                            }
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value: [
                                                  _vm.formDataF["loadingPlace"][
                                                    "code"
                                                  ],
                                                  _vm.formDataF["loadingPlace"][
                                                    "name"
                                                  ],
                                                  _vm.formDataF["loadingPlace"][
                                                    "countryName"
                                                  ]
                                                ],
                                                label: "Loading Place"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3"
                                            }
                                          },
                                          [
                                            _c("asyent-form-display", {
                                              attrs: {
                                                value: [
                                                  _vm.formDataF[
                                                    "dischargePlace"
                                                  ]["code"],
                                                  _vm.formDataF[
                                                    "dischargePlace"
                                                  ]["name"],
                                                  _vm.formDataF[
                                                    "dischargePlace"
                                                  ]["countryName"]
                                                ],
                                                label: "Discharge Place"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "12",
                                              md: "4",
                                              lg: "3"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-chip",
                                              { attrs: { color: "success" } },
                                              [_vm._v("NO DANGEROUS GOODS")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "12" } },
                                          [
                                            _c(
                                              "asyent-card",
                                              {
                                                attrs: {
                                                  title: "Voyage",
                                                  flat: "",
                                                  "border-color": 2,
                                                  "with-border": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value: [
                                                                        _vm
                                                                          .formDataF[
                                                                          "idCargoVoyage"
                                                                        ][
                                                                          "departurePort"
                                                                        ][
                                                                          "code"
                                                                        ],
                                                                        _vm
                                                                          .formDataF[
                                                                          "idCargoVoyage"
                                                                        ][
                                                                          "departurePort"
                                                                        ][
                                                                          "name"
                                                                        ],
                                                                        _vm
                                                                          .formDataF[
                                                                          "idCargoVoyage"
                                                                        ][
                                                                          "departurePort"
                                                                        ][
                                                                          "countryName"
                                                                        ]
                                                                      ],
                                                                      label:
                                                                        "Departure Port"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "idCargoVoyage"
                                                                        ][
                                                                          "voyageNumber"
                                                                        ],
                                                                      label:
                                                                        "Voyage Number"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "idCargoVoyage"
                                                                        ][
                                                                          "departureDate"
                                                                        ],
                                                                      "as-date":
                                                                        "",
                                                                      label:
                                                                        "Departure Date"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value: [
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportMeans"
                                                                        ][
                                                                          "idNumber"
                                                                        ],
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportMeans"
                                                                        ][
                                                                          "name"
                                                                        ]
                                                                      ],
                                                                      label:
                                                                        "Transport Means"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "12" } },
                                          [
                                            _c(
                                              "asyent-card",
                                              {
                                                attrs: {
                                                  title: "Transport Document",
                                                  flat: "",
                                                  "border-color": 2,
                                                  "with-border": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "transportDocumentType"
                                                                        ],
                                                                      label:
                                                                        "Document Type"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "transportDocumentReference"
                                                                        ],
                                                                      label:
                                                                        "Reference"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "contractCarriageCondition"
                                                                        ],
                                                                      label:
                                                                        "Contract Carriage Condition"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value: [
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "acceptanceDate"
                                                                        ],
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "acceptancePlace"
                                                                        ]
                                                                      ],
                                                                      label:
                                                                        "Acceptance Date"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "issueDate"
                                                                        ],
                                                                      label:
                                                                        "Issue Date"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 ma-0",
                                                                    attrs: {
                                                                      disabled: true,
                                                                      label:
                                                                        "Negotiable"
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "negotiable"
                                                                        ],
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .formDataF[
                                                                            "transportDocument"
                                                                          ],
                                                                          "negotiable",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "formDataF['transportDocument']['negotiable']"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 ma-0",
                                                                    attrs: {
                                                                      disabled: true,
                                                                      label:
                                                                        "eBL"
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "eBL"
                                                                        ],
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .formDataF[
                                                                            "transportDocument"
                                                                          ],
                                                                          "eBL",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "formDataF['transportDocument']['eBL']"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "3"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 ma-0",
                                                                    attrs: {
                                                                      disabled: true,
                                                                      label:
                                                                        "Part Bill"
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "transportDocument"
                                                                        ][
                                                                          "partBill"
                                                                        ],
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .formDataF[
                                                                            "transportDocument"
                                                                          ],
                                                                          "partBill",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "formDataF['transportDocument']['partBill']"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "12", md: "12" } },
                                          [
                                            _c(
                                              "asyent-card",
                                              {
                                                attrs: {
                                                  title: "PortCalls",
                                                  flat: "",
                                                  "border-color": 2,
                                                  "with-border": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c("v-data-table", {
                                                          staticClass:
                                                            "pa-0 ma-0",
                                                          attrs: {
                                                            headers:
                                                              _vm.portcallTableHeaders,
                                                            items:
                                                              _vm.formDataF[
                                                                "ports"
                                                              ],
                                                            "items-per-page": -1,
                                                            "hide-default-footer":
                                                              "",
                                                            "multi-sort": ""
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key:
                                                                "item.vehicleId",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item[
                                                                          "vehicleId"
                                                                        ]
                                                                      ) +
                                                                      " | " +
                                                                      _vm._s(
                                                                        item[
                                                                          "vehicleName"
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              }
                                                            },
                                                            {
                                                              key:
                                                                "item.locationCode",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var item =
                                                                  ref.item
                                                                return [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item[
                                                                          "locationCode"
                                                                        ]
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        item[
                                                                          "locationName"
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ])
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          flat: "",
                                          color: "bgWhite",
                                          title: "Carrier"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "asyent-form-display",
                                                          {
                                                            attrs: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "carrier"
                                                                ]["id"],
                                                              label: "SCAC Id."
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "asyent-form-display",
                                                          {
                                                            attrs: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "carrier"
                                                                ]["name"],
                                                              label:
                                                                "Name & Address"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          flat: "",
                                          color: "bgWhite",
                                          title: "Consignor"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "6",
                                                          lg: "6"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "asyent-form-display",
                                                          {
                                                            attrs: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "consignor"
                                                                ]["id"],
                                                              label: "Eori No."
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "6",
                                                          lg: "6"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "asyent-form-display",
                                                          {
                                                            attrs: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "consignor"
                                                                ][
                                                                  "providerRef"
                                                                ],
                                                              label:
                                                                "Provider Ref."
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "asyent-form-display",
                                                          {
                                                            attrs: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "consignor"
                                                                ]["name"],
                                                              label:
                                                                "Name & Address"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          flat: "",
                                          color: "bgWhite",
                                          title: "Consignee"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "6",
                                                          lg: "6"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "asyent-form-display",
                                                          {
                                                            attrs: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "consignee"
                                                                ]["id"],
                                                              label: "Eori No."
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "6",
                                                          lg: "6"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "asyent-form-display",
                                                          {
                                                            attrs: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "consignee"
                                                                ][
                                                                  "providerRef"
                                                                ],
                                                              label:
                                                                "Provider Ref."
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "asyent-form-display",
                                                          {
                                                            attrs: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "consignee"
                                                                ]["name"],
                                                              label:
                                                                "Name & Address"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          flat: "",
                                          color: "bgWhite",
                                          title: "Notify Party"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "6",
                                                          lg: "6"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "asyent-form-display",
                                                          {
                                                            attrs: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "notifyParty"
                                                                ]["id"],
                                                              label: "Eori No."
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "6",
                                                          lg: "6"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "asyent-form-display",
                                                          {
                                                            attrs: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "notifyParty"
                                                                ][
                                                                  "providerRef"
                                                                ],
                                                              label:
                                                                "Provider Ref."
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "asyent-form-display",
                                                          {
                                                            attrs: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "notifyParty"
                                                                ]["name"],
                                                              label:
                                                                "Name & Address"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              extended: "",
                              expandable: "",
                              title: "Risk Assessment Result"
                            }
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          title: "Pre-Loading Selectivity",
                                          flat: ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color": _vm.showRiskAsColor(
                                                      "plpSystemRiskLevel"
                                                    )
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "plpSystemRiskLevel"
                                                    ),
                                                    label: "System Risk"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color": _vm.showRiskAsColor(
                                                      "plpOfficerRiskLevel"
                                                    )
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "plpOfficerRiskLevel"
                                                    ),
                                                    label: "Officer Decision"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          title: "Pre-Arrival Selectivity",
                                          flat: ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color": _vm.showRiskAsColor(
                                                      "papSystemRiskLevel"
                                                    )
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "papSystemRiskLevel"
                                                    ),
                                                    label: "System Risk"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color": _vm.showRiskAsColor(
                                                      "papOfficerRiskLevel"
                                                    )
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "papOfficerRiskLevel"
                                                    ),
                                                    label: "Officer Decision"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          title: "Post-Arrival Selectivity",
                                          flat: ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color": _vm.showRiskAsColor(
                                                      "postSystemRiskLevel"
                                                    )
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "postSystemRiskLevel"
                                                    ),
                                                    label: "System Risk"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color": _vm.showRiskAsColor(
                                                      "postOfficerRiskLevel"
                                                    )
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "postOfficerRiskLevel"
                                                    ),
                                                    label: "Officer Decision"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          extended: "",
                                          expandable: "",
                                          title: "Selectivity Results"
                                        }
                                      },
                                      [
                                        _c("v-data-table", {
                                          staticClass: "pa-0 ma-0",
                                          attrs: {
                                            headers:
                                              _vm.selectivityTableHeaders,
                                            items:
                                              _vm.formDataF[
                                                "selectivityResults"
                                              ],
                                            "items-per-page": -1,
                                            "hide-default-footer": "",
                                            "multi-sort": ""
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "item.riskAnalysisCategory",
                                              fn: function(ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { text: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.selectivityDialog = true
                                                          _vm.selectivityItem = item
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _vm._v(
                                                            "mdi-folder-open"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item[
                                                              "riskAnalysisCategory"
                                                            ]
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        }),
                                        _c(
                                          "v-dialog",
                                          {
                                            attrs: {
                                              fullscreen:
                                                _vm.$vuetify.breakpoint
                                                  .smAndDown,
                                              persistent: "",
                                              scrollable: "",
                                              transition:
                                                "dialog-bottom-transition"
                                            },
                                            model: {
                                              value: _vm.selectivityDialog,
                                              callback: function($$v) {
                                                _vm.selectivityDialog = $$v
                                              },
                                              expression: "selectivityDialog"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              { attrs: { color: "bgWhite" } },
                                              [
                                                _c(
                                                  "v-toolbar",
                                                  {
                                                    attrs: {
                                                      color: "primary",
                                                      dark: "",
                                                      "max-height": "56"
                                                    }
                                                  },
                                                  [
                                                    _c("v-toolbar-title", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.selectivityItem[
                                                            "riskAnalysisCategory"
                                                          ]
                                                        ) +
                                                          " Risk Analysis on " +
                                                          _vm._s(
                                                            _vm.selectivityItem[
                                                              "eventTypeName"
                                                            ]
                                                          )
                                                      )
                                                    ]),
                                                    _c("v-spacer"),
                                                    _c("asyent-button", {
                                                      attrs: {
                                                        "button-icon":
                                                          "mdi-close",
                                                        "button-tooltip":
                                                          "Cancel",
                                                        dark: "",
                                                        icon: ""
                                                      },
                                                      on: {
                                                        confirmed: function(
                                                          $event
                                                        ) {
                                                          _vm.selectivityDialog = false
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .selectivityItem[
                                                                      "triggerTime"
                                                                    ],
                                                                  label:
                                                                    "Trigger Time"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .selectivityItem[
                                                                      "systemRiskLevel"
                                                                    ]
                                                                  ],
                                                                  label:
                                                                    "System Risk Level"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .selectivityItem[
                                                                      "riskAnalysisResult"
                                                                    ]
                                                                  ],
                                                                  label:
                                                                    "Risk Analysis Result"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .selectivityItem[
                                                                      "officerRiskLevel"
                                                                    ],
                                                                  label:
                                                                    "Officer Risk Assessment"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "12"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-card",
                                                              {
                                                                attrs: {
                                                                  title:
                                                                    "Hit Criteria",
                                                                  flat: "",
                                                                  "border-color": 2,
                                                                  "with-border":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-data-table",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 ma-0",
                                                                    attrs: {
                                                                      headers:
                                                                        _vm.criteriaTableHeaders,
                                                                      items:
                                                                        _vm
                                                                          .selectivityItem[
                                                                          "hitCriteria"
                                                                        ],
                                                                      "items-per-page": -1,
                                                                      "hide-default-footer":
                                                                        "",
                                                                      "multi-sort":
                                                                        ""
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "12"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-card",
                                                              {
                                                                attrs: {
                                                                  title:
                                                                    "Officer Risk Description",
                                                                  flat: "",
                                                                  "border-color": 2,
                                                                  "with-border":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .selectivityItem[
                                                                          "officerRiskDescription"
                                                                        ],
                                                                      label: ""
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "12"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-card",
                                                              {
                                                                attrs: {
                                                                  title:
                                                                    "Selected Controls",
                                                                  flat: "",
                                                                  "border-color": 2,
                                                                  "with-border":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-select",
                                                                  {
                                                                    attrs: {
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      "items-reload":
                                                                        _vm.controlTypesReload,
                                                                      "enable-select-all":
                                                                        "",
                                                                      "item-text":
                                                                        "description",
                                                                      "item-value":
                                                                        "code",
                                                                      label: "",
                                                                      multiple:
                                                                        "",
                                                                      name:
                                                                        "controlTypes",
                                                                      sortable:
                                                                        "",
                                                                      title:
                                                                        "Control Types"
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .selectivityItem[
                                                                          "controlTypes"
                                                                        ],
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.selectivityItem,
                                                                          "controlTypes",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "selectivityItem['controlTypes']"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              extended: "",
                              expandable: "",
                              title: "Controls Results"
                            }
                          },
                          [
                            _c("v-data-table", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                headers: _vm.controlTableHeaders,
                                items: _vm.formDataF["consignmentControls"],
                                "items-per-page": -1,
                                "hide-default-footer": "",
                                "multi-sort": ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.id",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "form-display-div" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: _vm.showControlLink(
                                                  item["id"]
                                                ),
                                                tag: "a"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [_vm._v("mdi-folder-open")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "form-display-text form-display-link",
                                                  staticStyle: {
                                                    color: "unset"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item["controlType"]
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              extended: "",
                              expandable: "",
                              title: "Consignment Items"
                            }
                          },
                          [
                            _c("v-data-table", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                headers: _vm.itemTableHeaders,
                                items: _vm.formDataF["consignmentItems"],
                                "items-per-page": -1,
                                "hide-default-footer": "",
                                "multi-sort": ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.itemNumber",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "" },
                                          on: {
                                            click: function($event) {
                                              _vm.itemDialog = true
                                              _vm.selectedItem = item
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("mdi-folder-open")]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(item["itemNumber"]) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c(
                              "v-dialog",
                              {
                                attrs: {
                                  fullscreen: _vm.$vuetify.breakpoint.smAndDown,
                                  persistent: "",
                                  scrollable: "",
                                  transition: "dialog-bottom-transition"
                                },
                                model: {
                                  value: _vm.itemDialog,
                                  callback: function($$v) {
                                    _vm.itemDialog = $$v
                                  },
                                  expression: "itemDialog"
                                }
                              },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { color: "bgWhite" } },
                                  [
                                    _c(
                                      "v-toolbar",
                                      {
                                        attrs: {
                                          color: "primary",
                                          dark: "",
                                          "max-height": "56"
                                        }
                                      },
                                      [
                                        _c("v-toolbar-title", [
                                          _vm._v(
                                            "Item No. " +
                                              _vm._s(
                                                _vm.selectedItem["itemNumber"]
                                              )
                                          )
                                        ]),
                                        _c("v-spacer"),
                                        _c("asyent-button", {
                                          attrs: {
                                            "button-icon": "mdi-close",
                                            "button-tooltip": "Cancel",
                                            dark: "",
                                            icon: ""
                                          },
                                          on: {
                                            confirmed: function($event) {
                                              _vm.itemDialog = false
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "asyent-card",
                                                  {
                                                    attrs: {
                                                      color: "bgWhite",
                                                      flat: "",
                                                      title: "Item Details"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .selectedItem[
                                                                      "hsCode"
                                                                    ]
                                                                  ],
                                                                  label:
                                                                    "HS Code"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .selectedItem[
                                                                      "commodityCode"
                                                                    ]
                                                                  ],
                                                                  label:
                                                                    "Commodity Code"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "6"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .selectedItem[
                                                                      "description"
                                                                    ],
                                                                  label:
                                                                    "Description of Goods"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .selectedItem[
                                                                      "grossWeight"
                                                                    ],
                                                                  label:
                                                                    "Gross Weight"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .selectedItem[
                                                                      "transportPackages"
                                                                    ][
                                                                      "packageNumber"
                                                                    ],
                                                                    _vm
                                                                      .selectedItem[
                                                                      "transportPackages"
                                                                    ][
                                                                      "packageTypeCode"
                                                                    ],
                                                                    _vm
                                                                      .selectedItem[
                                                                      "transportPackages"
                                                                    ][
                                                                      "packageTypeName"
                                                                    ]
                                                                  ],
                                                                  label:
                                                                    "Packages"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "6"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .selectedItem[
                                                                      "transportPackages"
                                                                    ][
                                                                      "shippingMarks"
                                                                    ],
                                                                  label:
                                                                    "shippingMarks"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "12"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-card",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "bgWhite",
                                                                  flat: "",
                                                                  title:
                                                                    "Stuffing"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-data-table",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 ma-0",
                                                                    attrs: {
                                                                      headers:
                                                                        _vm.stuffingTableHeaders,
                                                                      items:
                                                                        _vm
                                                                          .selectedItem[
                                                                          "stuffedEquipments"
                                                                        ],
                                                                      "items-per-page": -1,
                                                                      "hide-default-footer":
                                                                        "",
                                                                      "multi-sort":
                                                                        ""
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              extended: "",
                              expandable: "",
                              title: "Transport Equipments"
                            }
                          },
                          [
                            _c("v-data-table", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                headers: _vm.equipmentTableHeaders,
                                items: _vm.formDataF["transportEquipments"],
                                "items-per-page": -1,
                                "hide-default-footer": "",
                                "multi-sort": ""
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              extended: "",
                              expandable: "",
                              title: "Additional Information"
                            }
                          },
                          [
                            _c("v-data-table", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                headers: _vm.addInfoTableHeaders,
                                items: _vm.formDataF["additionalInformation"],
                                "items-per-page": -1,
                                "hide-default-footer": "",
                                "multi-sort": ""
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              extended: "",
                              expandable: "",
                              title: "Documents"
                            }
                          },
                          [
                            _c("v-data-table", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                headers: _vm.documentTableHeaders,
                                items: _vm.formDataF["documents"],
                                "items-per-page": -1,
                                "hide-default-footer": "",
                                "multi-sort": ""
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              extended: "",
                              expandable: "",
                              title: "Events"
                            }
                          },
                          [
                            _c("v-data-table", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                headers: _vm.eventTableHeaders,
                                items: _vm.formDataF["events"],
                                "items-per-page": -1,
                                "hide-default-footer": "",
                                "multi-sort": ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.eventId",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "form-display-div" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: _vm.showEventLink(
                                                  item["eventId"]
                                                ),
                                                tag: "a",
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "text-decoration": "unset"
                                                  },
                                                  attrs: { color: "primary" }
                                                },
                                                [_vm._v("mdi-file-link")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "form-display-text form-display-link",
                                                  staticStyle: {
                                                    color: "unset"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item["eventId"]) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.formValid,
          callback: function($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }