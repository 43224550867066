import { render, staticRenderFns } from "./consignments.vue?vue&type=template&id=0d1b5880&scoped=true&"
import script from "./consignments.vue?vue&type=script&lang=js&"
export * from "./consignments.vue?vue&type=script&lang=js&"
import style0 from "./consignments.vue?vue&type=style&index=0&id=0d1b5880&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d1b5880",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\asyhub-master\\asyhub-maritime-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0d1b5880')) {
      api.createRecord('0d1b5880', component.options)
    } else {
      api.reload('0d1b5880', component.options)
    }
    module.hot.accept("./consignments.vue?vue&type=template&id=0d1b5880&scoped=true&", function () {
      api.rerender('0d1b5880', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/cargo/pages/consignments/consignments.vue"
export default component.exports